import React, { useEffect, useState } from "react"
import "./blockSteps.scss"

const BlockSteps = ({
  data,
  line = "middle",
  title = "Comment s'abonner ?",
  classBlock = "bc-2",
  classTitle = "",
  clasLabel = "",
  sizeItem = "",
  chiffre = false,
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const resizeEvent = () => {
    const mobile = 768

    if (window.innerWidth <= mobile) {
      setIsMobile(true)
      return
    } else {
      setIsMobile(false)
      return
    }
  }
  useEffect(() => {
    resizeEvent()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        resizeEvent()
      })
    }
  }, [])
  return (
    <div className={"block-steps br-22 px-8 py-5 " + classBlock}>
      {title.includes("<h3>") ? (
        <div
          className={`ta-c c-4 title-step ${isMobile ? "fs-28" : "fs-34"} mb-6`}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></div>
      ) : (
        <h3
          className={`ta-c c-4 ff-gb lh-48 title-step ${
            isMobile ? "fs-28" : "fs-34"
          } ${classTitle}`}
        >
          {title}
        </h3>
      )}
      <ul
        className={
          !chiffre
            ? "ul d-f fd-md-c ai-fs ai-md-c jc-md-c jc-c"
            : "ul d-f fd-md-c ai-fs ai-md-c jc-md-c jc-sb"
        }
      >
        {!chiffre
          ? data.map((element, index) => (
              <div className="d-f ai-c card-block-step " key={index}>
                <li className={"li d-f fd-c ai-c py-md-2 li-icon" + sizeItem}>
                  <div className="w-100 d-f jc-c p-r icon-fix">
                    <div className="d-f ai-c">
                      <span className="fs-40 ff-gb mr-2 c-1 d-ib mr-md-1 ">
                        {index + 1}
                      </span>
                      <span
                        style={{ height: "77px", width: "77px" }}
                        className={
                          element.icon.hasOwnProperty("path")
                            ? "d-f jc-c ai-c"
                            : "d-f jc-c ai-c icon icon-illustration icon-steps-" +
                              element.icon
                        }
                      >
                        {/** ONLY DRUPAL IMAGE */}
                        {element.icon?.path && (
                          <img
                            src={element.icon?.path}
                            alt=""
                            className="w-100 h-100 of-contain"
                          ></img>
                        )}
                      </span>
                    </div>
                    {index + 1 !== data.length && line === "icon" && (
                      <div className="separator icon mr-2 ml-2"></div>
                    )}
                    {element?.addSeparator && (
                      <div className="separator last icon mr-2 ml-2"></div>
                    )}
                  </div>
                  <div className="detail">
                    <p
                      className={
                        "cg-7 fs-16  lh-24 ta-c mb-0 mt-md-2 " + clasLabel
                      }
                    >
                      {element.label}
                    </p>
                    {element.hasOwnProperty("detail") && (
                      <p className="fs-14 lh-24 cg-7 mb-0 ta-c mt-1">
                        {element.detail}
                      </p>
                    )}
                  </div>
                </li>
                {index + 1 !== data.length && line === "middle" && (
                  <div className="separator mr-2 ml-2"></div>
                )}
              </div>
            ))
          : data.map((element, index) => (
              <>
                <div className="d-f ai-c card-block-step " key={index}>
                  <li
                    className={"li d-f fd-c ai-c py-md-2 li-chiffre" + sizeItem}
                  >
                    <div className="w-100 d-f jc-c p-r icon-fix">
                      <div className="d-f ai-c">
                        <span className="d-f jc-c lh-67 ai-c c-1 fs-40 mb-1 ff-gb">
                          {element.field_label_first_value}
                        </span>
                      </div>
                    </div>
                    <div className="ta-c js-c ai-c">
                      <span className="cg-7 fs-16 lh-24 ta-c mb-0 mt-md-2 ff-gbook">
                        {element.field_label_second_value}
                      </span>
                    </div>
                    {index + 1 !== data.length && line === "middle" && (
                      <div className="separator chiffre-md mr-2 ml-2"></div>
                    )}
                  </li>
                </div>
                <div className="separator chiffre-lg mr-2 ml-2"></div>
              </>
            ))}
      </ul>
    </div>
  )
}

export default BlockSteps
