import React,{ ReactChildren, ReactChild } from 'react';
import SliderReact from "react-slick";
import "./sliderMateriel.scss";


interface AuxProps {
    children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[],
    settings?:Object
  }

const SliderMateriel = ({ children , settings={
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1}}: AuxProps) => {
    if(children){
      return (
          <SliderReact {...settings}>{children}</SliderReact>
      );
    }
    return <></>
};

export default SliderMateriel;