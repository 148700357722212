import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import Layout from "../../components/layout/layout"
import "./materielDetail.scss"  
import links from "../../utils/links"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import ImgCustom from "../../components/imgCustom/imgCustom"
import BlocMaterielSeo from "../../pages/materiels/Components/BlocSeo/BlocMaterielSeo"
import Seo from "../../components/seo/seo"
import BlockSteps from "../../components/blockSteps/BlockSteps"
import config from "../../utils/config"
import BlocTechnique from "../../pages/materiels/Components/BlocTechnique/BlocTechnique"
import utils from "../../utils/utils"
import tracking from "../../utils/tracking"
import { getTaxoMaterials } from "../../hooks/hooks"
import SliderMateriel from "./sliderMateriel/sliderMateriel"


const materielDetail = ({ data, pageContext }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [slideToShowValue, setSlideToShowValue] = useState(6)

  const resizeEvent = () => {
    const tablet = 1170
    const mobile = 768

    if (window.innerWidth > tablet) {
      setIsMobile(false)
      setSlideToShowValue(6)
      return
    }
    if (window.innerWidth <= tablet && window.innerWidth >= mobile) {
      setSlideToShowValue(6)
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= mobile) {
      setSlideToShowValue(1)
      setIsMobile(true)
      return
    }
  }
  useEffect(() => {
    resizeEvent()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        resizeEvent()
      })
    }
  }, [])
    const materielData = getTaxoMaterials().sort((a, b) =>
      a.chrono > b.chrono ? 1 : -1
    )
    const [campagne, setCampagne] = useState("")
    const [isTherecampagne, setIsThereCampagne] = useState(false)
    useEffect(() => {
      /** Tracking */
      tracking.track("typeTemplate", "ficheproduit")
      tracking.track("page_name", pageContext.nom_produit)
      tracking.track("categorie", "telesurveillance")
      tracking.trackInit()
      utils.startPageWindow()
    }, [])
    useEffect(() => {
      if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
        setCampagne(localStorage.getItem("campaign"))
        setIsThereCampagne(true)
      }
    }, [])

    const dataGraphQl = useStaticQuery(graphql`
    query MaterialDetail {
      nodeHomepage {
        field_titre_abonnement
        relationships {
          field_step_illustration_abonneme {
            weight
            field_titre
            relationships {
              field_picto {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

    const breadcrumb = 
        [{
          name: "Accueil",
          url: links.getLink("home") + campagne,
        },
        {
          name: "Matériel",
          url: links.getLink("materiel") + campagne,
        },
        {
          name: pageContext.nom_produit,
          url: links.getLink("materiel") + pageContext.urlProduit + "/" + campagne,
        },]
    
        const blockSteps =
        dataGraphQl.nodeHomepage.relationships.field_step_illustration_abonneme
          ?.sort((a, b) => (a.weight > b.weight ? 1 : -1))
          .map(el => ({
            label: el.field_titre,
            icon: {
              path: el.relationships.field_picto.localFile.publicURL,
            },
          }))

    const seoTitle = pageContext.baliseTitle != " " ? pageContext.baliseTitle : pageContext.nom_produit

    return (
      <Layout isViewPromoOnHeader={false} forceIsScroll={false}>
        <Seo
          title={seoTitle}
          description={pageContext.metaDescription}
          linkCanonical={"materiels/" + pageContext.urlProduit + "/"} 
          page={undefined}/>
        <div className="bc-2 d-f fd-c ">
            <Breadcrumb data={breadcrumb} addStyle={"p-1"} />
        </div>
        <div className="title wrapper d-f fd-c ta-c jc-c ai-c mt-8 mt-md-5">
            {!isMobile && 
            <div className="p-64">
              <div className="rvtitre-block" dangerouslySetInnerHTML={{
                  __html: "<h1 className=\"ff-gb rvtitre\">" + pageContext.titre_h1 + "</div>",
                  }}>
              </div>
              <p className="ff-gm cg-7" dangerouslySetInnerHTML={{__html: pageContext.sous_titre_h1}}></p>
            </div>
            }
            {isMobile && 
              <><div className="rvtitre-block" dangerouslySetInnerHTML={{
              __html: "<h1 className=\"ff-gb rvtitre\">" + pageContext.titre_h1 + "</div>",
              }}>
            </div><p className="ff-gm cg-7" dangerouslySetInnerHTML={{ __html: pageContext.sous_titre_h1 }}></p></>}
        </div>
          <div className={"jc-c d-f fd-r ai-c p-r heroProduit" + (isMobile ? "Mobile pt-3" : " pb-8 py-md-5")}>
            {isMobile && 
              <div className={"imageProduitMobile " + (isMobile ? " mb-4" : " mr-2 ml-71")}>
              <ImgCustom
                src={pageContext.imageProduit}
                alt={pageContext.imageProduitAlt}
              />
              </div>
            }
            <div className="jc-c d-f">
              <div className={"text wrapper" + (isMobile ? " blocTextMobile" : " blocText")}>
                <h2
                  className="ff-gb pb-3 titreBloc ff-gb lh-32 fs-24"
                  dangerouslySetInnerHTML={{
                    __html: pageContext.titreH2.value,
                  }}
                ></h2>
                <div className="line d-f">
                  {pageContext.caracteristiqueBloc1.map((el, key) => (
                    <div className="d-f">
                      <div className="w-24">
                        <div className="iconeProduit d-i mr-1">
                          <ImgCustom
                            src={pageContext.iconesList[key].localFile}
                            alt={"aaa"}
                          />
                        </div>    
                      </div>
                    <div className="ff-gbook cg-7 mb-3 detailText fs-14 lh-24 d-i"
                        dangerouslySetInnerHTML={{
                          __html: el,
                        }}>
                    </div>
                  </div>
                  ))} 
                </div>
                <div className="detailText fs-14 lh-24 mt-24 ff-gbook" dangerouslySetInnerHTML={{
                        __html: pageContext.texte_bloc_1.value,
                      }}>
                </div>
                <a href={links.getLink("diag") + campagne} className={"ff-gbold td-n btn-hover-c1 d-f ai-c c-p bc-1 c-w fw-b fs-14 br-24" + (isMobile ? " mBDevisMobile" : " mBDevis")}>
                  <span
                    style={{ height: "20px", width: "20px", minWidth: "20px" }}
                    className="ml-3 mr-1 icon-start-diag"
                  ></span>
                  <span className="mBDevisTxt c-w"
                  dangerouslySetInnerHTML={{__html: pageContext.titreCTA1}}>
                  </span>
                </a>
              </div>
              {!isMobile && 
              <div className={"imageProduit " + (isMobile ? "" : " ")}>
              <ImgCustom
                src={pageContext.imageProduit}
                alt={pageContext.imageProduitAlt}
              />
              </div>
              }
            </div>
          </div>
        <div className={"mb-" + (isMobile ? "4" : "8")}>
            <BlocTechnique data={true} imageTechnique={pageContext.imageTechnique} imageTechniqueAlt={pageContext.imageTechniqueAlt} txtTechnique={pageContext.fiche_technique_details}/>
        </div>
        <div className={"mb-" + (isMobile ? "4" : "8")}>
            <BlocMaterielSeo data={true} titre={pageContext.titre_seo} soustitre={pageContext.sous_titre_seo} txt1={pageContext.bloc_seo_texte_1.value} txt2={pageContext.bloc_seo_texte_2.value} txtCTA={pageContext.titreCTA2}/>
        </div>
        {/** Liste Materiels */}
        {isMobile &&
          <div className="jc-c mb-4 ta-c">
            <h2 className="ff-gb">Découvrez nos autres matériels</h2>
            <div className="sousTitreSuggestion lh-24 fs-16 mb-4 jc-c ff-gbook">{pageContext.sous_titre_suggestion}</div>
          </div>          
        }
        <div className={"suggestionBloc suggestion-block wrapper ta-c box-shadow-m py-4 mb-4 d-f jc-c fd-c" + (isMobile ? " w-95I" : "")}>
          {!isMobile &&
            <><h2 className="ff-gb">Découvrez nos autres matériels</h2>
            <div className="sousTitreSuggestion lh-24 fs-16 mb-4 jc-c ff-gbook">{pageContext.sous_titre_suggestion}</div></>
          }
          <ul className="ul list-materiels d-ib ai-fs jc-c sliderMateriel">
            <SliderMateriel settings={{
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: slideToShowValue,
                slidesToScroll: 1,
              }}>
              {pageContext.materielDict.map((elm, index) => (
                <li className="li px-3" key={index}>
                  <a
                    href= {links.getLink("materiel") + elm[0] + "/" + campagne}
                    className={
                      pageContext.nom_produit === elm[1]
                        ? "materielSuggestion item d-c fd-c jc-c ai-c is-active"
                        : "materielSuggestion item d-c fd-c jc-c ai-c"
                    }
                  >
                    <div className="carrouselimg d-f jc-c ai-c mx-a">
                      <ImgCustom
                        src={elm[3]}
                        alt={elm[2]}
                      />
                    </div>
                    <h3 className="target-label mb-0 mt-1 fs-14 lh-24 ta-c">
                      {elm[1]}
                    </h3>
                  </a>
                </li>
              ))}
            </SliderMateriel>
          </ul>
        </div>

        {/** BLOCK STEPS */}
        <section className="pt-8 pb-8">
          <div className={"wrapper " + (isMobile ? "" : "produitFinalBloc d-f jc-c ai-c")}>
            <BlockSteps
              title={dataGraphQl.nodeHomepage.field_titre_abonnement}
              data={blockSteps}
            />
          </div>
        </section>
      </Layout>
    )
  
}

export default materielDetail
